import classNames from 'classnames/bind';
import Box from 'src/components/ui-kit/box/box';
import Table from 'src/components/ui-kit/table/table';
import TableCell from 'src/components/ui-kit/table/_cell';
import TableRow from 'src/components/ui-kit/table/_row';
import GoogleReviewSVG from 'public/images/google-review.svg';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './review-box.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import { nanoid } from 'nanoid';
import StarsSVG from 'public/images/stars.svg';

const classesContext = classNames.bind(styles);

SwiperCore.use([Pagination, Autoplay]);

export default function ReviewBox({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:pages:home:review-box']);

  const swiperSettings = {
    pagination: {
      clickable: true
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    spaceBetween: 50,
    slidesPerView: 1
  };

  const propertiesListedItems = [
    t('TEXT_TRUSTED_SINCE'),
    t('TEXT_PROVEN_AND_SIMPLE_PROCESS'),
    t('TEXT_NO_COST_TO_LIST'),
    t('TEXT_HIGHEST_SALES_PRICES')
  ];

  const items = [
    {
      id: nanoid(),
      text: t('SLIDES.FIRST_SLIDE.TEXT_TESTIMONIAL'),
      author: t('SLIDES.FIRST_SLIDE.TEXT_AUTHOR'),
      authorLocation: t('SLIDES.FIRST_SLIDE.TEXT_AUTHOR_LOCATION')
    },
    {
      id: nanoid(),
      text: t('SLIDES.SECOND_SLIDE.TEXT_TESTIMONIAL'),
      author: t('SLIDES.SECOND_SLIDE.TEXT_AUTHOR'),
      authorLocation: t('SLIDES.SECOND_SLIDE.TEXT_AUTHOR_LOCATION')
    },
    {
      id: nanoid(),
      text: t('SLIDES.THIRD_SLIDE.TEXT_TESTIMONIAL'),
      author: t('SLIDES.THIRD_SLIDE.TEXT_AUTHOR'),
      authorLocation: t('SLIDES.THIRD_SLIDE.TEXT_AUTHOR_LOCATION')
    },
    {
      id: nanoid(),
      text: t('SLIDES.FOURTH_SLIDE.TEXT_TESTIMONIAL'),
      author: t('SLIDES.FOURTH_SLIDE.TEXT_AUTHOR'),
      authorLocation: t('SLIDES.FOURTH_SLIDE.TEXT_AUTHOR_LOCATION')
    },
    {
      id: nanoid(),
      text: t('SLIDES.FIFTH_SLIDE.TEXT_TESTIMONIAL'),
      author: t('SLIDES.FIFTH_SLIDE.TEXT_AUTHOR'),
      authorLocation: t('SLIDES.FIFTH_SLIDE.TEXT_AUTHOR_LOCATION')
    }
  ];

  return (
    <Box
      heading={t('TEXT_1_MINERAL_RIGHTS_MARKETPLACE_IN_THE_UNITED_STATES')}
      className={classesContext(['reviewbox', className])}
      {...restProps}
    >
      <Table className={styles.table}>
        <TableRow
          isHeader
          className={classesContext('table_row', 'table_row__desktop')}>
          <TableCell className={styles.table_cell__properties}>{t('TEXT_NATIONAL_EXPOSURE')}</TableCell>
          <TableCell className={classesContext(['table_cell__accented', 'table_cell__testimonials'])}>
            {t('TEXT_CUSTOMER_REVIEWS')}
          </TableCell>
          <TableCell className={styles.table_cell__trusted}>{t('TEXT_TRUSTED_WEBSITE')}</TableCell>
        </TableRow>
        <TableRow className={styles.table_row}>
          <TableCell
            isHeader
            className={classesContext('table_header', 'table_header__tablet')}>
            {t('TEXT_NATIONAL_EXPOSURE')}
          </TableCell>
          <TableCell className={classesContext(['table_cell', 'table_cell__properties'])}>
            <ul className={styles.reviewbox_list}>
              {propertiesListedItems.map((item, key) => (
                <li
                  key={key}
                  className={styles.reviewbox_list__item}>
                  <Icon
                    variant={IconVariant.NEXT}
                    theme={IconTheme.SECONDARY} />
                  {item}
                </li>
              ))}
            </ul>
          </TableCell>
          <TableCell
            isHeader
            className={classesContext('table_header', 'table_header__gray', 'table_header__tablet')}>
            {t('TEXT_CUSTOMER_REVIEWS')}
          </TableCell>
          <TableCell className={classesContext(['table_cell', 'table_cell__testimonials'])}>
            <Swiper
              {...swiperSettings}
              className={styles.reviewbox_description}>
              {items.map((item) => (
                <SwiperSlide
                  key={item.id}
                  className={styles.reviewbox_slide}>
                  <p>{item.text}</p>
                  <div className={styles.reviewbox_slide_inner}>
                    <div className={styles.reviewbox_slide_author}>
                      <div>{item.author}</div>
                      <div>{item.authorLocation}</div>
                    </div>
                    <Image
                      src={StarsSVG}
                      alt='stars'
                      width={104}
                      height={20} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </TableCell>
          <TableCell
            isHeader
            className={classesContext('table_header', 'table_header__tablet')}>
            {t('TEXT_TRUSTED_WEBSITE')}
          </TableCell>
          <TableCell className={classesContext(['table_cell', 'table_cell__trusted'])}>
            <Link href='/blog/sell-mineral-rights/us-mineral-exchange-success-stories/'>
              <a className={styles.reviewbox_review}>
                <Image
                  alt={t('TEXT_GOOGLE_CUSTOMER_REVIEWS')}
                  width='164'
                  height='107'
                  src={GoogleReviewSVG} />
              </a>
            </Link>
          </TableCell>
        </TableRow>
      </Table>
    </Box>
  );
}
