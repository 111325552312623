import 'reflect-metadata';
import SliderBox from 'src/components/pages/home/slider-box/slider-box';
import styles from './index.module.scss';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps, GetStaticPropsContext } from 'next';
import { i18n } from 'next-i18next.config';
import { wrapper } from 'src/store';
import Head from 'next/head';
import { configuration } from '../configurations/configuration';
import { LayoutType } from '../components/layout/layout';
import { AppState } from 'src/store/state';
import { Store } from 'redux';
import { FeaturedListingsActions, loadFeaturedListingsTask } from 'src/store/featured-listings';
import { loadHomeMenuTask, NavigationActions } from 'src/store/navigation';
import VideoBox from 'src/components/pages/home/video-box/video-box';
import FeaturedListingsPreview from 'src/components/common/featured-listings-preview/featured-listings-preview';
import MineralBuyer from 'src/components/pages/home/mineral-buyer/mineral-buyer';
import ReviewBox from 'src/components/pages/home/review-box/review-box';
import Title from 'src/components/pages/home/title/title';

const HomePage = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>Sell Mineral Rights at US Mineral Exchange</title>
        <meta
          name='description'
          content='Find out why mineral owners trust US Mineral Exchange to Sell Mineral Rights. If you want to sell mineral rights for maximum value, we can help!'
        />
        <meta
          name='keywords'
          content='' />
      </Head>
      <div className={styles.homepage}>
        <Title className={styles.homepage_title} />
        <SliderBox className={styles.homepage_tabs} />
        <VideoBox
          src={configuration.youtube.url ?? ''}
          className={styles.homepage_video} />
        <FeaturedListingsPreview className={styles.homepage_listings} />
        <MineralBuyer className={styles.homepage_mineral} />
        <ReviewBox className={styles.homepage_review} />
      </div>
    </>
  );
};

HomePage.layout = LayoutType.HOME;

export default HomePage;

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(
  (store: Store<AppState>) => async ({ locale }: GetStaticPropsContext) => {
    store.dispatch(FeaturedListingsActions.loadFeaturedListingsForServer());
    store.dispatch(NavigationActions.loadHomeMenuForServer());

    await loadFeaturedListingsTask?.toPromise();
    await loadHomeMenuTask?.toPromise();

    return {
      props: {
        ...(await serverSideTranslations(locale ?? i18n.defaultLocale, [
          'common',
          'components:layout',
          'components:common:featured-listing-preview',
          'components:pages:home:title',
          'components:pages:home:mineral-buyer',
          'components:pages:home:review-box',
          'components:pages:home:video-box'
        ]))
      },
      revalidate: configuration.revalidation.timeSec
    };
  }
);
