import classNames from 'classnames/bind';
import Box from 'src/components/ui-kit/box/box';
import ButtonLink from 'src/components/ui-kit/button/__link';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import Table from 'src/components/ui-kit/table/table';
import TableCell from 'src/components/ui-kit/table/_cell';
import TableRow from 'src/components/ui-kit/table/_row';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './featured-listings-preview.module.scss';
import FeaturedListingsPreviewItem from './_item';
import { useSelector } from 'react-redux';
import { FeaturedListingsSelectors } from 'src/store/featured-listings';

const classesContext = classNames.bind(styles);

export default function FeaturedListingsPreview({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:common:featured-listing-preview']);

  const listings = useSelector(FeaturedListingsSelectors.items);

  return (
    <Box
      heading={
        <>
          <span className='text__primary'>{t('TEXT_FEATURED')}</span>&nbsp;
          {t('TEXT_LISTINGS')}
        </>
      }
      className={classesContext(['listings', className])}
      {...restProps}
    >
      <Table className={styles.listings_table}>
        <TableRow
          isHeader
          className={styles.listings_header}>
          <TableCell className={classesContext(['table_cell', 'table_cell__type'])}>{t('TEXT_TYPE')}</TableCell>
          <TableCell className={classesContext(['table_cell', 'table_cell__state'])}>{t('TEXT_STATE')}</TableCell>
          <TableCell className={classesContext(['table_cell', 'table_cell__county'])}>{t('TEXT_COUNTY')}</TableCell>
        </TableRow>
        {listings?.map((listing) => (
          <FeaturedListingsPreviewItem
            key={listing.id}
            listing={listing} />
        ))}
      </Table>
      <div className={styles.listings_actions}>
        <ButtonLink
          href='/mineral-listings'
          className={styles.listings_action}>
          {t('BUTTON_VIEW_ALL_LISTING')}
          <Icon
            variant={IconVariant.NEXT}
            theme={IconTheme.WHITE} />
        </ButtonLink>
      </div>
    </Box>
  );
}
