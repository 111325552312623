import classNames from 'classnames/bind';
import Box from 'src/components/ui-kit/box/box';
import Link from 'next/link';
import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './slider-box.module.scss';
import SliderBoxTab from './_tab';
import { NavigationSelectors } from 'src/store/navigation';
import { useSelector } from 'react-redux';
import SliderBoxSlide from './_slide';

const classesContext = classNames.bind(styles);

SwiperCore.use([Autoplay]);

export default function SliderBox({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const homeMenu = useSelector(NavigationSelectors.homeMenu);

  const [activeTab, setActiveTab] = useState<number>(homeMenu[0]?.id);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const getTabIndexByID = (tabID: number): number => {
    return homeMenu.findIndex((tab) => tab?.id === tabID) ?? 0;
  };

  const getTabIDByIndex = (index: number): number | null => {
    return homeMenu[index]?.id;
  };

  const handleActiveTab = (tabID: number): void => {
    setActiveTab(tabID);
    swiper?.slideTo(getTabIndexByID(tabID), 2000);
  };

  const handleSlideChange = (swiper: SwiperCore): void => {
    const tabID = getTabIDByIndex(swiper.activeIndex);

    if (tabID) {
      setActiveTab(tabID);
    }
  };

  const swiperSettings = {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    spaceBetween: 0,
    slidesPerView: 1
  };

  return (
    <Box
      className={classesContext(['slider', className])}
      {...restProps}>
      <div className={styles.slider_wrapper}>
        <Swiper
          {...swiperSettings}
          onSwiper={setSwiper}
          onSlideChange={handleSlideChange}
          className={styles.slider_swiper}
        >
          {homeMenu &&
            homeMenu.map((item) => (
              <SwiperSlide
                key={item.id}
                className={styles.slider_slide}>
                <SliderBoxSlide slide={item} />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className={styles.slider_rightbar}>
          {homeMenu &&
            homeMenu.map((item) => (
              <Link
                passHref
                key={item.id}
                href={item.link}>
                <SliderBoxTab
                  key={item.id}
                  isActive={activeTab === item.id}
                  onMouseOver={() => handleActiveTab(item.id)}
                >
                  {item.name}
                </SliderBoxTab>
              </Link>
            ))}
        </div>
      </div>
    </Box>
  );
}
