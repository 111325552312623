import classNames from 'classnames/bind';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './table.module.scss';

const classesContext = classNames.bind(styles);

export default function Table({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  return <div
    className={classesContext(['table', className])}
    {...restProps} />;
}
