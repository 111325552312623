import classNames from 'classnames/bind';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './slider-box.module.scss';
import Button, { ButtonSize } from './../../../ui-kit/button/button';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import { Navigation } from 'src/entities/navigation';
import Link from 'next/link';

const classesContext = classNames.bind(styles);

export default function SliderBoxSlide({
  className,
  slide,
  ...restProps
}: {
  slide: Navigation;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  return (
    <div
      className={classesContext(['slider_main', className])}
      {...restProps}>
      <img
        src={slide.linkImage}
        alt={slide.name}
        className={styles.slider_image} />
      <div className={styles.slider_content}>
        {slide.title && <h2 className={styles.slider_title}>{slide.title}</h2>}
        {slide.text && <p className={styles.slider_description}>{slide.text}</p>}
        {slide.btnText && (
          <div className={styles.slider_actions}>
            <Link href={slide.link}>
              <a>
                <Button
                  size={ButtonSize.LARGE}
                  type='button'>
                  {slide.btnText}&nbsp;
                  <Icon
                    variant={IconVariant.ARROW}
                    theme={IconTheme.WHITE} />
                </Button>
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
