import classNames from 'classnames/bind';
import ButtonLink from 'src/components/ui-kit/button/__link';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import TableCell from 'src/components/ui-kit/table/_cell';
import TableRow from 'src/components/ui-kit/table/_row';
import { Listing } from 'src/entities/listing';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './featured-listings-preview.module.scss';
import Link from 'next/link';
import { useMediaQuery } from '@react-hooks-library/core';

const classesContext = classNames.bind(styles);

export default function FeaturedListingsPreviewItem({
  listing,
  className,
  ...restProps
}: { listing: Listing } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:common:featured-listing-preview']);

  const isMobile = useMediaQuery(`(max-width: ${styles.mobile})`);

  return (
    <TableRow
      className={classesContext(['listing_item', 'table_row', className])}
      {...restProps}>
      <TableCell className={classesContext(['table_cell', 'table_cell__type'])}>
        <span className={styles.table_cell_content__desktop}>
          {t(`TYPES.TEXT_${listing.type.toLocaleUpperCase()}`)}
        </span>
        <div className={styles.table_cell_content__mobile}>
          <span className={styles.table_cell_text}>{t('TEXT_TYPE')}:</span>
          <span className={styles.table_cell_text}>{t('TEXT_STATE')}:</span>
          <span className={styles.table_cell_text}>{t('TEXT_COUNTY')}:</span>
        </div>
      </TableCell>
      <TableCell className={classesContext(['table_cell', 'table_cell__state'])}>
        <span className={styles.table_cell_content__desktop}>{listing.state?.name}</span>
        <div className={styles.table_cell_content__mobile}>
          <span className={styles.table_cell_text}>{t(`TYPES.TEXT_${listing.type.toLocaleUpperCase()}`)}</span>
          <span className={styles.table_cell_text}>{listing.state?.name}</span>
          <span className={styles.table_cell_text}>{listing.county?.name}</span>
        </div>
      </TableCell>
      <TableCell className={classesContext(['table_cell', 'table_cell__county'])}>
        <span className={styles.table_cell_content__desktop}>{listing.county?.name}</span>
      </TableCell>
      <TableCell className={classesContext(['table_cell', 'table_cell__actions'])}>
        <div className={styles.listings_item_actions}>
          <Link
            href={listing.url}
            passHref>
            <ButtonLink className={styles.listings_button}>
              <span className={styles.listings_button_text}>{t('BUTTON_VIEW_LISTING')}</span>
              <Icon
                variant={IconVariant.NEXT}
                theme={!isMobile ? IconTheme.WHITE : undefined} />
            </ButtonLink>
          </Link>
        </div>
      </TableCell>
    </TableRow>
  );
}
