import { useTranslation } from 'next-i18next';
import styles from './title.module.scss';
import classNames from 'classnames/bind';

const classesContext = classNames.bind(styles);

export default function Title({ className }: { className?: string }): JSX.Element {
  const { t } = useTranslation(['components:pages:home:title']);

  return <h1 className={classesContext([className, styles.title])}>{t('TEXT_TITLE')}</h1>;
}
