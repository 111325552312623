import classNames from 'classnames/bind';
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, Ref } from 'react';
import { ButtonVariant } from './button';
import styles from './button.module.scss';

const classesContext = classNames.bind(styles);

export interface ButtonLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  variant?: ButtonVariant;
}

function ButtonLink(
  { children, className, variant = ButtonVariant.SECONDARY, ...restProps }: ButtonLinkProps,
  ref: Ref<HTMLAnchorElement>
): JSX.Element {
  return (
    <a
      ref={ref}
      className={classesContext(['button', 'button__link', `button__${variant ? variant : 'primary'}`, className])}
      {...restProps}
    >
      {children}
    </a>
  );
}

export default forwardRef(ButtonLink);
