import classNames from 'classnames/bind';
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, Ref } from 'react';
import styles from './slider-box.module.scss';

const classesContext = classNames.bind(styles);

function SliderBoxTab(
  {
    children,
    isActive,
    ...restProps
  }: {
    isActive: boolean;
  } & DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  ref: Ref<HTMLAnchorElement>
): JSX.Element {
  return (
    <a
      ref={ref}
      className={classesContext(['tab', { tab__active: isActive }])}
      {...restProps}>
      {children}
    </a>
  );
}

export default forwardRef(SliderBoxTab);
