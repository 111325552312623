import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import { DetailedHTMLProps, LiHTMLAttributes } from 'react';
import styles from './mineral-buyer.module.scss';

export default function MineralBuyerItem({
  children
}: DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>): JSX.Element {
  return (
    <li className={styles.mineralbuyer_item}>
      <Icon
        variant={IconVariant.NEXT}
        theme={IconTheme.SECONDARY} />
      <span className={styles.mineralbuyer_item_item_label}>{children}</span>
    </li>
  );
}
