import classNames from 'classnames/bind';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './table.module.scss';

const classesContext = classNames.bind(styles);

export default function TableRow({
  isHeader,
  className,
  ...restProps
}: {
  isHeader?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  return <div
    className={classesContext(['table_row', { table_row__header: isHeader }, className])}
    {...restProps} />;
}
