import classNames from 'classnames/bind';
import Box from 'src/components/ui-kit/box/box';
import ButtonLink from 'src/components/ui-kit/button/__link';
import Icon, { IconTheme, IconVariant } from 'src/components/ui-kit/icon/icon';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import styles from './mineral-buyer.module.scss';
import MineralBuyerItem from './_item';
import { useSelector } from 'react-redux';
import { AuthSelectors } from 'src/store/auth';

const classesContext = classNames.bind(styles);

export default function MineralBuyer({
  className,
  ...restProps
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:pages:home:mineral-buyer']);

  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);

  const items = [
    t('LINK_NEW_LISTINGS_WEEKLY'),
    t('LINK_POWERFUL_LISTING_NAVIGATOR'),
    t('LINK_E_MAIL_LISTING_ALERTS'),
    t('LINK_EXCLUSIVE_SELLERS')
  ];

  return (
    <Box
      heading={
        <>
          <span className='text__primary'>{t('TEXT_MINERAL')}</span>&nbsp;
          {t('TEXT_BUYER')}
        </>
      }
      {...restProps}
      className={classesContext(['mineralbuyer', className])}
    >
      <ul className={styles.mineralbuyer_items}>
        {items.map((item) => (
          <MineralBuyerItem key={item}>{item}</MineralBuyerItem>
        ))}
      </ul>
      <div className={styles.mineralbuyer_actions}>
        <ButtonLink
          href={isAuthenticated ? '/profile' : '/buy-mineral-rights/create-buyers-account'}
          className={styles.mineralbuyer_button}
        >
          {t('BUTTON_REGISTER_NOW')}
          <Icon
            variant={IconVariant.NEXT}
            theme={IconTheme.WHITE} />
        </ButtonLink>
      </div>
    </Box>
  );
}
