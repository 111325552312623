import classNames from 'classnames/bind';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './video-box.module.scss';
import LiteYouTubeEmbed from 'react-lite-youtube-embed/dist';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { useTranslation } from 'next-i18next';

const classesContext = classNames.bind(styles);

export default function VideoBox({
  src,
  className,
  ...restProps
}: { src: string } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(['components:pages:home:video-box']);

  return (
    <div
      className={classesContext(['videobox', className])}
      {...restProps}>
      {src && (
        <LiteYouTubeEmbed
          id='WJB7BP0yhr8'
          title={t('TEXT_YOUTUBE_DESCRIPTION')}
          wrapperClass={styles.videobox_wrapper}
          iframeClass={styles.videobox_iframe}
          playerClass={styles.videobox_btn}
          activatedClass={styles.videobox_activated}
          poster='maxresdefault'
          params='rel=0&enablejsapi=1'
        />
      )}
    </div>
  );
}
